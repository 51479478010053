body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: helvetica neue,Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

.show-when-hover {
  opacity: 0;
}

.show-when-hover:hover {
  opacity: 1;
}

.Resizer {
  box-sizing: border-box;
  background-color: #302b3f;
  opacity: 0.5;
  z-index: 1;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 10px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.separateur {
  color: #ff506e;
  font-weight: 900;
  line-height: 28px;
  content: "\f142";
  font-size: 28px;
  font-family: "Font Awesome 5 Free";
  position:absolute;
  top:50%;
  right:0;
  margin-right:-9px;
  margin-top:-14px;
}

.largeur-fixe-bouton {
  width:130px;
}

.z-index-middle {
  z-index:100;
}

.z-index-front {
  z-index:200;
}

.trns {
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

/* Remove outline and box shadow */
textarea:active,
input[type="text"]:active,
textarea:focus,
input[type="text"]:focus,
button:focus,
button:active,
label:focus,
.btn:active,
.btn.active
{
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
}

/* Peer video controls for mobile */
.peer-camera-controls-mobile {
  top:2px;
  left:0px;
  width:100%;
  position:absolute;
  background:#333;
  padding:0.25rem;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/* main message panel */

.msg_wrap {
  height: calc(100% - 125px);
}

.chat_panel_mobile {
  position: absolute;
  top:100px;
  height:calc(100vh - 300px);
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  pointer-events: none;
  overflow:hidden;
}

.chat_message_mobile_container {
  padding: 10px 10px;
  position: relative;
  background-color: lightgrey;
  border-radius: 4px;
  margin:10px;
  opacity:0.6;
}

.chat_message_mobile_right {
  align-self: flex-end;
}

.chat_message_mobile_left {
  align-self: flex-start;
}

.chat_message_mobile_right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 10px;
  width: 0;
  z-index: 1;
  right: -10px;
  border: medium solid lightgrey;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent lightgrey;
}

.chat_message_mobile_left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 10px;
  width: 0;
  z-index: 1;
  left: -10px;
  border: medium solid lightgrey;
  border-width: 10px 10px 10px 0;
  border-color: transparent lightgrey transparent transparent;
}


.chat_input {
  bottom: 0px;
  left: 10px;
  padding: 5px 10px 10px;
  background: #fff;
  width: calc(100% - 20px);
  position: absolute;
}

.chat_input_mobile {
  background: #fff;
  width: 100%;
}

.chat_input #send_btn {
  border: none;
  border-radius: 0 5px 5px 0;
  font-size: 15px;
  font-weight: 700;
  padding: 12px 0;
  vertical-align: top;
  width: 100px;
  line-height: inherit;
  background: #302b3f;
  display: inline-block;
  text-align: center;
  color: #fff;
}

.chat_input #send_btn:disabled {
  opacity: 0.65;
}

.ci_iw {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 105px);
  position: relative;
}

.emoji-container {
  padding: 0!important;
  position: relative;
  border: 1px solid #dbdbdb;
}

.chat-emoji {
  color: #fe5670;
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.form-control {
  background: #f5f5f5;
  border: 1px solid #d2dbe3;
  border-radius: 5px 0 0 5px;
  font-size: 18px;
  margin-bottom: 0;
  height: 45px;
  line-height: 45px;
  padding: 6px 35px 6px 6px;
  outline: 0;
  overflow: auto;
  user-select: text
}

.form-control:focus {
  background: #eee
}

.form-control div {
  padding: 0 35px 0 10px;
  outline: none
}

.emoji-wysiwyg {
  word-wrap: break-word;
  overflow: auto;
  -webkit-user-select: text;
  padding: 7px;
}

/* Animation typing */
.rlt-chat-typing {
  background: #f5f5f5;
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  bottom: 60px;
  left: 20px;
  z-index: 10
}

.rlt-chat-typing:before {
  content: '';
  border: 5px solid transparent;
  border-top-color: #f5f5f5;
  border-right-color: #f5f5f5;
  position: absolute;
  bottom: -10px;
  left: 10px
}

.rlt-chat-typing .tc-dot {
  background: #302b3f;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 1px;
  border-radius: 50%;
  animation: wave 1.2s linear infinite
}

.rlt-chat-typing .tc-dot:nth-child(2) {
  animation-delay: -.8s
}

.rlt-chat-typing .tc-dot:nth-child(3) {
  animation-delay: -.4s
}

@keyframes wave {
  0%, 40%, 100% {
    opacity: 1;
    transform: initial
  }
  30% {
    opacity: .3;
    -webkit-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px)
  }
}

/* Audio range */

.remaud_wrp {
  display:inline-flex;
  position: absolute;
  top: 3px;
  left: 5px;
  white-space: nowrap;
  opacity: 1;
  visibility: visible;
  z-index: 1
}

#remote_audio_button {
  background: rgba(0, 0, 0, .5);
  border-radius: 5px;
  color: #ff506e;
  cursor: pointer;
  line-height: 28px;
  width: 35px;
  height: 28px;
  position: absolute;
  z-index: 2;
  font-size: 20px;
  display: inline-block;
  position: relative;
  opacity: 1;
  margin-right:5px;
  text-align:center;
}

#audio_range {
  -webkit-appearance: none;
  background: 0 0;
  vertical-align: middle
}

#audio_range:focus {
  outline: none
}

#audio_range::-webkit-slider-runnable-track {
  width: 100%;
  height: 28px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #2b2b2b;
  background: rgba(0, 0, 0, .5);
  border-radius: 4px;
  border: 0 solid #000
}

#audio_range::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px gray;
  border: 0 solid #000;
  height: 25px;
  width: 20px;
  border-radius: 4px;
  background: #ff506e;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 1px
}

#audio_range::-moz-range-track {
  width: 100%;
  height: 28px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #2b2b2b;
  background: rgba(0, 0, 0, .5);
  border-radius: 4px;
  border: 0 solid #000
}

#audio_range::-moz-range-thumb {
  box-shadow: 1px 1px 1px gray;
  border: 0 solid #000;
  height: 25px;
  width: 20px;
  border-radius: 12px;
  background: #ff506e;
  cursor: pointer
}

/* ********* Gender Select ************ */

.gender-select {
  background: #fff;
  border: 1px solid #bcd4e2;
  border-radius: 3px;
  color: #555;
  display: inline-block;
  font-size: 21px;
  letter-spacing: 1px;
  position: relative;
  text-align: left;
  z-index: 10;
  width:100%;
}

.gender-select span {
  cursor: pointer;
  display: inline-block;
  padding: 15px;
}

.gender-select span:after {
  content: '\f078';
  color: #fe5670;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  position: absolute;
  top: 50%;
  right: 18px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gender-select ul {
  background: #fff;
  border: 1px solid #c2d3e1;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  padding: 0 25px;
  position: absolute;
  left: -1px;
  min-width: calc(100% + 2px);
  transform: translateY(-1px);
  margin: 0;
  list-style-type: disc;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  color: #555;
  font-size: 21px;
  letter-spacing: 1px;
  text-align: left;
}

.gender-select ul li {
  list-style-type: none;
}

.gender-select ul li input {
  display: none;
  box-sizing: border-box;
  padding: 0;
}

.gender-select ul li label {
  color: #555;
  cursor: pointer;
  border-bottom: 1px solid #dfe7ed;
  display: block;
  padding: 5px 0;
  margin-bottom: 7px;
  white-space: nowrap;
}

.gender-select ul li label:hover {
  color: #fe5670;
}


/* ********* Emojis ************ */
.emoji-lst {
  background: url(./emoji.png);
  background-size: 222px 222px;
  display: inline-block;
  height: 100%;
  width: 100%;
}

img.emoji {
  background: url(./emoji.png);
  display: inline-block;
  vertical-align: middle;
  margin: 2px 1px 0;
}

/* ********* Flags ************ */

.cntr_wrap {
  float: right;
  display:flex;
  margin-bottom: .25rem;
}

#cntr_sel {
  background: #f9f9f9;
  cursor: pointer;
  border: 1px solid #b5b5b5;
  border-radius: 3px;
  font-size: 16px;
  padding: 10px 15px;
  transition: all .25s ease
}

#cntr_sel:hover {
  background: #efefef
}

#cntr_pref:before {
  position: relative;
  top: 1px
}

.cl_wrap {
  position:absolute;
  background: #fbf8f5;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  box-shadow: 1px 1px 4px #757575;
  display: none;
  top: 70px;
  right: 8px;
  width: calc(100% - 13px);
  height: auto;
  max-height: calc(100% - 75px);
  overflow: auto;
  z-index: 100001
}

.cl_wrap_mobile {
  position:fixed;
  background: #fbf8f5;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  box-shadow: 1px 1px 4px #757575;
  display: none;
  top: 90px;
  left: 0px;
  width:100%;
  overflow: auto;
  z-index: 100001
}


#cntr_lst {
  border-top: 1px solid #c2c2c2;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  margin: 33px 0 5px;
  padding: 5px 0 0
}

#cntr_lst:after {
  display: table;
  clear: both
}

#cntr_lst li {
  cursor: pointer;
  display: block;
  font-size: 15px;
  list-style-type: none;
  padding: 1px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

#cntr_lst li.active, #cntr_lst li:hover {
  background: #302b3f;
  color: #333
}

#cntr_lst #all_cntr {
  display: block;
  cursor: default;
  padding: 3px;
  width: inherit;
  position: absolute;
  top: 1px;
  right: 50%;
  -webkit-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  -o-transform: translate(50%, 0);
  transform: translate(50%, 0)
}

#cntr_lst #all_cntr:hover {
  background: 0 0
}

#cntr_lst #all_cntr span {
  background: #f3f3f3;
  border: 1px solid #b1b1b1;
  border-radius: 4px;
  display: inline-block;
  padding: 1px 10px;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

#cntr_lst #all_cntr span:hover {
  background: #e0e0e0
}

#close_cl {
  position:absolute;
  cursor: pointer;
  color: #5f5f5f;
  top: 1px;
  right: 10px;
  font-size: 20px
}

#flag {
  color: #222;
  font-size: 15px;
  z-index: 1!important
}

.flag, .flag:before {
  display: inline-block;
}

.flag {
  position: relative;
}

.flag:before {
  content: " ";
  width: 16px;
  height: 11px;
  background: url(./flags2.png) no-repeat;
  margin-right: 5px
}

.flag.flag-gw:before {
  background-position: -144px -55px
}

.flag.flag-gu:before {
  background-position: -128px -55px
}

.flag.flag-gt:before {
  background-position: -112px -55px
}

.flag.flag-gs:before {
  background-position: -96px -55px
}

.flag.flag-gr:before {
  background-position: -80px -55px
}

.flag.flag-gq:before {
  background-position: -64px -55px
}

.flag.flag-gp:before {
  background-position: -48px -55px
}

.flag.flag-gy:before {
  background-position: -160px -55px
}

.flag.flag-gg:before {
  background-position: -208px -44px
}

.flag.flag-gf:before {
  background-position: -192px -44px
}

.flag.flag-ge:before {
  background-position: -176px -44px
}

.flag.flag-gd:before {
  background-position: -160px -44px
}

.flag.flag-gb:before {
  background-position: -144px -44px
}

.flag.flag-ga:before {
  background-position: -128px -44px
}

.flag.flag-gn:before {
  background-position: -32px -55px
}

.flag.flag-gm:before {
  background-position: -16px -55px
}

.flag.flag-gl:before {
  background-position: 0 -55px
}

.flag.flag-gi:before {
  background-position: -240px -44px
}

.flag.flag-gh:before {
  background-position: -224px -44px
}

.flag.flag-lb:before {
  background-position: -208px -77px
}

.flag.flag-lc:before {
  background-position: -224px -77px
}

.flag.flag-la:before {
  background-position: -192px -77px
}

.flag.flag-tv:before {
  background-position: -32px -154px
}

.flag.flag-tw:before {
  background-position: -48px -154px
}

.flag.flag-tt:before {
  background-position: -16px -154px
}

.flag.flag-tr:before {
  background-position: 0 -154px
}

.flag.flag-lk:before {
  background-position: 0 -88px
}

.flag.flag-li:before {
  background-position: -240px -77px
}

.flag.flag-lv:before {
  background-position: -80px -88px
}

.flag.flag-to:before {
  background-position: -240px -143px
}

.flag.flag-lt:before {
  background-position: -48px -88px
}

.flag.flag-lu:before {
  background-position: -64px -88px
}

.flag.flag-lr:before {
  background-position: -16px -88px
}

.flag.flag-ls:before {
  background-position: -32px -88px
}

.flag.flag-th:before {
  background-position: -128px -143px
}

.flag.flag-tf:before {
  background-position: -96px -143px
}

.flag.flag-tg:before {
  background-position: -112px -143px
}

.flag.flag-td:before {
  background-position: -80px -143px
}

.flag.flag-tc:before {
  background-position: -64px -143px
}

.flag.flag-ly:before {
  background-position: -96px -88px
}

.flag.flag-do:before {
  background-position: -112px -33px
}

.flag.flag-dm:before {
  background-position: -96px -33px
}

.flag.flag-dj:before {
  background-position: -64px -33px
}

.flag.flag-dk:before {
  background-position: -80px -33px
}

.flag.flag-um:before {
  background-position: -112px -154px
}

.flag.flag-de:before {
  background-position: -48px -33px
}

.flag.flag-ye:before {
  background-position: -96px -165px
}

.flag.flag-dz:before {
  background-position: -128px -33px
}

.flag.flag-uy:before {
  background-position: -144px -154px
}

.flag.flag-yt:before {
  background-position: -112px -165px
}

.flag.flag-catalonia:before {
  background-position: -32px -22px
}

.flag.flag-vu:before {
  background-position: -16px -165px
}

.flag.flag-qa:before {
  background-position: -128px -121px
}

.flag.flag-tm:before {
  background-position: -208px -143px
}

.flag.flag-england:before {
  background-position: -208px -33px
}

.flag.flag-eh:before {
  background-position: -192px -33px
}

.flag.flag-wf:before {
  background-position: -48px -165px
}

.flag.flag-ee:before {
  background-position: -160px -33px
}

.flag.flag-eg:before {
  background-position: -176px -33px
}

.flag.flag-za:before {
  background-position: -128px -165px
}

.flag.flag-ec:before {
  background-position: -144px -33px
}

.flag.flag-us:before {
  background-position: -128px -154px
}

.flag.flag-eu:before {
  background-position: -16px -44px
}

.flag.flag-et:before {
  background-position: 0 -44px
}

.flag.flag-zw:before {
  background-position: -176px -165px
}

.flag.flag-es:before {
  background-position: -240px -33px
}

.flag.flag-er:before {
  background-position: -224px -33px
}

.flag.flag-ru:before {
  background-position: -192px -121px
}

.flag.flag-rw:before {
  background-position: -208px -121px
}

.flag.flag-rs:before {
  background-position: -176px -121px
}

.flag.flag-re:before {
  background-position: -144px -121px
}

.flag.flag-it:before {
  background-position: -176px -66px
}

.flag.flag-ro:before {
  background-position: -160px -121px
}

.flag.flag-tk:before {
  background-position: -176px -143px
}

.flag.flag-tz:before {
  background-position: -64px -154px
}

.flag.flag-bd:before {
  background-position: -16px -11px
}

.flag.flag-be:before {
  background-position: -32px -11px
}

.flag.flag-bf:before {
  background-position: -48px -11px
}

.flag.flag-bg:before {
  background-position: -64px -11px
}

.flag.flag-vg:before {
  background-position: -224px -154px
}

.flag.flag-ba:before {
  background-position: -240px 0
}

.flag.flag-bb:before {
  background-position: 0 -11px
}

.flag.flag-tibet:before {
  background-position: -144px -143px
}

.flag.flag-bm:before {
  background-position: -112px -11px
}

.flag.flag-bn:before {
  background-position: -128px -11px
}

.flag.flag-bo:before {
  background-position: -144px -11px
}

.flag.flag-bh:before {
  background-position: -80px -11px
}

.flag.flag-bj:before {
  background-position: -96px -11px
}

.flag.flag-bt:before {
  background-position: -192px -11px
}

.flag.flag-jm:before {
  background-position: -208px -66px
}

.flag.flag-bv:before {
  background-position: -208px -11px
}

.flag.flag-bw:before {
  background-position: -224px -11px
}

.flag.flag-ws:before {
  background-position: -64px -165px
}

.flag.flag-br:before {
  background-position: -160px -11px
}

.flag.flag-bs:before {
  background-position: -176px -11px
}

.flag.flag-je:before {
  background-position: -192px -66px
}

.flag.flag-by:before {
  background-position: -240px -11px
}

.flag.flag-bz:before {
  background-position: 0 -22px
}

.flag.flag-tn:before {
  background-position: -224px -143px
}

.flag.flag-om:before {
  background-position: -144px -110px
}

.flag.flag-zm:before {
  background-position: -160px -165px
}

.flag.flag-ua:before {
  background-position: -80px -154px
}

.flag.flag-jo:before {
  background-position: -224px -66px
}

.flag.flag-mz:before {
  background-position: -192px -99px
}

.flag.flag-ck:before {
  background-position: -128px -22px
}

.flag.flag-xk:before {
  background-position: -80px -165px
}

.flag.flag-ci:before {
  background-position: -112px -22px
}

.flag.flag-ch:before {
  background-position: -96px -22px
}

.flag.flag-co:before {
  background-position: -192px -22px
}

.flag.flag-cn:before {
  background-position: -176px -22px
}

.flag.flag-cm:before {
  background-position: -160px -22px
}

.flag.flag-cl:before {
  background-position: -144px -22px
}

.flag.flag-ca:before {
  background-position: -16px -22px
}

.flag.flag-cg:before {
  background-position: -80px -22px
}

.flag.flag-cf:before {
  background-position: -64px -22px
}

.flag.flag-cd:before {
  background-position: -48px -22px
}

.flag.flag-cz:before {
  background-position: -32px -33px
}

.flag.flag-cy:before {
  background-position: -16px -33px
}

.flag.flag-vc:before {
  background-position: -192px -154px
}

.flag.flag-cr:before {
  background-position: -208px -22px
}

.flag.flag-cw:before {
  background-position: 0 -33px
}

.flag.flag-cv:before {
  background-position: -240px -22px
}

.flag.flag-cu:before {
  background-position: -224px -22px
}

.flag.flag-ve:before {
  background-position: -208px -154px
}

.flag.flag-pr:before {
  background-position: -48px -121px
}

.flag.flag-ps:before {
  background-position: -64px -121px
}

.flag.flag-pw:before {
  background-position: -96px -121px
}

.flag.flag-pt:before {
  background-position: -80px -121px
}

.flag.flag-py:before {
  background-position: -112px -121px
}

.flag.flag-tl:before {
  background-position: -192px -143px
}

.flag.flag-iq:before {
  background-position: -128px -66px
}

.flag.flag-pa:before {
  background-position: -160px -110px
}

.flag.flag-pf:before {
  background-position: -192px -110px
}

.flag.flag-pg:before {
  background-position: -208px -110px
}

.flag.flag-pe:before {
  background-position: -176px -110px
}

.flag.flag-pk:before {
  background-position: -240px -110px
}

.flag.flag-ph:before {
  background-position: -224px -110px
}

.flag.flag-pn:before {
  background-position: -32px -121px
}

.flag.flag-kurdistan:before {
  background-position: -128px -77px
}

.flag.flag-pl:before {
  background-position: 0 -121px
}

.flag.flag-pm:before {
  background-position: -16px -121px
}

.flag.flag-hr:before {
  background-position: -224px -55px
}

.flag.flag-ht:before {
  background-position: -240px -55px
}

.flag.flag-hu:before {
  background-position: 0 -66px
}

.flag.flag-hk:before {
  background-position: -176px -55px
}

.flag.flag-hn:before {
  background-position: -208px -55px
}

.flag.flag-vn:before {
  background-position: 0 -165px
}

.flag.flag-hm:before {
  background-position: -192px -55px
}

.flag.flag-jp:before {
  background-position: -240px -66px
}

.flag.flag-wales:before {
  background-position: -32px -165px
}

.flag.flag-me:before {
  background-position: -160px -88px
}

.flag.flag-md:before {
  background-position: -144px -88px
}

.flag.flag-mg:before {
  background-position: -176px -88px
}

.flag.flag-ma:before {
  background-position: -112px -88px
}

.flag.flag-mc:before {
  background-position: -128px -88px
}

.flag.flag-uz:before {
  background-position: -160px -154px
}

.flag.flag-mm:before {
  background-position: -240px -88px
}

.flag.flag-ml:before {
  background-position: -224px -88px
}

.flag.flag-mo:before {
  background-position: -16px -99px
}

.flag.flag-mn:before {
  background-position: 0 -99px
}

.flag.flag-mh:before {
  background-position: -192px -88px
}

.flag.flag-mk:before {
  background-position: -208px -88px
}

.flag.flag-mu:before {
  background-position: -112px -99px
}

.flag.flag-mt:before {
  background-position: -96px -99px
}

.flag.flag-mw:before {
  background-position: -144px -99px
}

.flag.flag-mv:before {
  background-position: -128px -99px
}

.flag.flag-mq:before {
  background-position: -48px -99px
}

.flag.flag-mp:before {
  background-position: -32px -99px
}

.flag.flag-ms:before {
  background-position: -80px -99px
}

.flag.flag-mr:before {
  background-position: -64px -99px
}

.flag.flag-im:before {
  background-position: -80px -66px
}

.flag.flag-ug:before {
  background-position: -96px -154px
}

.flag.flag-my:before {
  background-position: -176px -99px
}

.flag.flag-mx:before {
  background-position: -160px -99px
}

.flag.flag-il:before {
  background-position: -64px -66px
}

.flag.flag-va:before {
  background-position: -176px -154px
}

.flag.flag-sa:before {
  background-position: -224px -121px
}

.flag.flag-ae:before {
  background-position: -16px 0
}

.flag.flag-ad:before {
  background-position: 0 0
}

.flag.flag-ag:before {
  background-position: -48px 0
}

.flag.flag-af:before {
  background-position: -32px 0
}

.flag.flag-ai:before {
  background-position: -64px 0
}

.flag.flag-vi:before {
  background-position: -240px -154px
}

.flag.flag-is:before {
  background-position: -160px -66px
}

.flag.flag-ir:before {
  background-position: -144px -66px
}

.flag.flag-am:before {
  background-position: -96px 0
}

.flag.flag-al:before {
  background-position: -80px 0
}

.flag.flag-ao:before {
  background-position: -128px 0
}

.flag.flag-an:before {
  background-position: -112px 0
}

.flag.flag-as:before {
  background-position: -160px 0
}

.flag.flag-ar:before {
  background-position: -144px 0
}

.flag.flag-au:before {
  background-position: -192px 0
}

.flag.flag-at:before {
  background-position: -176px 0
}

.flag.flag-aw:before {
  background-position: -208px 0
}

.flag.flag-in:before {
  background-position: -96px -66px
}

.flag.flag-ic:before {
  background-position: -16px -66px
}

.flag.flag-az:before {
  background-position: -224px 0
}

.flag.flag-ie:before {
  background-position: -48px -66px
}

.flag.flag-id:before {
  background-position: -32px -66px
}

.flag.flag-zanzibar:before {
  background-position: -144px -165px
}

.flag.flag-ni:before {
  background-position: -32px -110px
}

.flag.flag-nl:before {
  background-position: -48px -110px
}

.flag.flag-no:before {
  background-position: -64px -110px
}

.flag.flag-na:before {
  background-position: -208px -99px
}

.flag.flag-nc:before {
  background-position: -224px -99px
}

.flag.flag-scotland:before {
  background-position: -16px -132px
}

.flag.flag-ne:before {
  background-position: -240px -99px
}

.flag.flag-nf:before {
  background-position: 0 -110px
}

.flag.flag-ng:before {
  background-position: -16px -110px
}

.flag.flag-nz:before {
  background-position: -128px -110px
}

.flag.flag-sh:before {
  background-position: -80px -132px
}

.flag.flag-np:before {
  background-position: -80px -110px
}

.flag.flag-so:before {
  background-position: -176px -132px
}

.flag.flag-nr:before {
  background-position: -96px -110px
}

.flag.flag-nu:before {
  background-position: -112px -110px
}

.flag.flag-somaliland:before {
  background-position: -192px -132px
}

.flag.flag-fr:before {
  background-position: -112px -44px
}

.flag.flag-io:before {
  background-position: -112px -66px
}

.flag.flag-sv:before {
  background-position: 0 -143px
}

.flag.flag-sb:before {
  background-position: -240px -121px
}

.flag.flag-fi:before {
  background-position: -32px -44px
}

.flag.flag-fj:before {
  background-position: -48px -44px
}

.flag.flag-fk:before {
  background-position: -64px -44px
}

.flag.flag-fm:before {
  background-position: -80px -44px
}

.flag.flag-fo:before {
  background-position: -96px -44px
}

.flag.flag-tj:before {
  background-position: -160px -143px
}

.flag.flag-sz:before {
  background-position: -48px -143px
}

.flag.flag-sy:before {
  background-position: -32px -143px
}

.flag.flag-sx:before {
  background-position: -16px -143px
}

.flag.flag-kg:before {
  background-position: -16px -77px
}

.flag.flag-ke:before {
  background-position: 0 -77px
}

.flag.flag-ss:before {
  background-position: -224px -132px
}

.flag.flag-sr:before {
  background-position: -208px -132px
}

.flag.flag-ki:before {
  background-position: -48px -77px
}

.flag.flag-kh:before {
  background-position: -32px -77px
}

.flag.flag-kn:before {
  background-position: -80px -77px
}

.flag.flag-km:before {
  background-position: -64px -77px
}

.flag.flag-st:before {
  background-position: -240px -132px
}

.flag.flag-sk:before {
  background-position: -112px -132px
}

.flag.flag-kr:before {
  background-position: -112px -77px
}

.flag.flag-si:before {
  background-position: -96px -132px
}

.flag.flag-kp:before {
  background-position: -96px -77px
}

.flag.flag-kw:before {
  background-position: -144px -77px
}

.flag.flag-sn:before {
  background-position: -160px -132px
}

.flag.flag-sm:before {
  background-position: -144px -132px
}

.flag.flag-sl:before {
  background-position: -128px -132px
}

.flag.flag-sc:before {
  background-position: 0 -132px
}

.flag.flag-kz:before {
  background-position: -176px -77px
}

.flag.flag-ky:before {
  background-position: -160px -77px
}

.flag.flag-sg:before {
  background-position: -64px -132px
}

.flag.flag-se:before {
  background-position: -48px -132px
}

.flag.flag-sd:before {
  background-position: -32px -132px
}

/* Responsive media queries */
.my_video {
  margin-top:-20px;
  z-index:10;
  width:40%;
  height:125px;
}

@media only screen and (max-width:768px) {
  .my_video {
    width:210px;
    height: 160px;
    margin-top: -45px;
  }
  #flag {
    color: #fff;
    margin-top: 5px;
    padding-right: 40px;
    font-size: 15px;
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    z-index: 10000!important;
    width: calc(100% - 115px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right
  }

  .chat_input {
    position: relative;
    /* haut | droit | bas | gauche */
    padding: 0 0 2px 0;
    background: #fff;
    width: 100%;
    left:0;
  }

  .chat-div {
    width:calc( 100% - 180px);
    padding-top: 1rem;
    background: #302b3f;
  }
  .chat_input #send_btn {
    padding: 8px 0;
    width: 100px;
    font-size: 15px;
  }
  .ci_iw {
    width: calc(100% - 100px);
  }
  .chat-icon-button {
    font-size: 22px;
    line-height: initial;
  }

}

@media only screen and (max-width:600px) {
  .my_video {
    width:180px;
    height: 145px;
    margin-top: -20px;
  }
  .form-control {
    height: 45px;
    font-size: 18px;
    line-height: 45px;
  }
  .chat_input {
    position: relative;
    /* haut | droit | bas | gauche */
    padding: 0 0 2px 0;
    background: #fff;
    width: 100%;
    left:0;
  }
  .chat-div {
    width:calc( 100% - 180px);
    padding-top: 1rem;
    background: #302b3f;
  }
  .chat_input #send_btn {
    padding: 8px 0;
    width: 100px;
    font-size: 15px;
  }
  .ci_iw {
    width: calc(100% - 100px);
  }
  .chat-icon-button {
    font-size: 22px;
    line-height: initial;
  }
  .form-check {
    margin: 0 30px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width:480px) {
  .my_video {
    width:130px;
    height: 105px;
    margin-top:0px;
  }
  .ci_iw {
    width: calc(100% - 50px);
  }
  .form-control {
    height: 38px;
    font-size: 13px;
    line-height: 38px;
  }
  .chat-emoji {
    font-size: 23px;
  }
  .chat-div {
    width:calc( 100% - 135px);
    padding-top: .25rem;
    background: #302b3f;
  }

  .chat_input {
    position: relative;
    /* haut | droit | bas | gauche */
    padding: 0 0 2px 0;
    background: #fff;
    width: 100%;
    left:0;
  }

  .chat-icon-button {
    font-size: 22px;
    line-height: initial;
  }
  .chat_input #send_btn {
    padding: 8px 0;
    width: 50px;
    font-size: 0;
  }

  .responsive-title {
    font-size: 28px;
    font-weight: 600;
    margin: 0 0 5px;
    line-height: normal;
  }
  .responsive-subtitle {
    font-size: 18px;
    margin-top: 5px;
    font-weight: 500;
    line-height: 1.1;
  }

}

@media only screen and (max-width:400px) {
  .my_video {
    width:125px;
    height: 115px;
  }
  .ci_iw {
    width: calc(100% - 50px);
  }
  .form-control {
    height: 32px;
    font-size: 12px;
    line-height: 32px;
  }
  .chat-emoji {
    font-size: 23px;
  }
  .chat-div {
    width:calc( 100% - 125px);
    background: #302b3f;
    padding-bottom: .25rem;
  }

  .chat_input {
    position: relative;
    padding: 0;
    background: #fff;
    width: 100%;
    left:0;
  }

  .chat-icon-button {
    font-size: 22px;
    line-height: initial;
  }
  .chat_input #send_btn {
    padding: 8px 0;
    width: 50px;
    font-size: 0;
  }

}

@media only screen and (max-width:340px) {
  .responsive-subtitle {
    font-size: 14px;
    margin-top: 5px;
    font-weight: 500;
    line-height: 1.1;
  }
}
